import React, { useEffect, useRef, useState } from "react";

import {
  getwindowmenupermission,
  savewindowmenupermission,
} from "../../../services/AdminApiServices";
import { UserRole } from "../../../core/json/UserRole";
import { useForm } from "react-hook-form";
import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";
import toast from "react-hot-toast";
import { Checkbox } from "antd";
import { ChevronDown} from "feather-icons-react/build/IconComponents";

const WindowMenuPermission = ({ data }) => {
  const { register, setValue, getValues, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [parent, setparent] = useState([]);
  const [PermissionData, setPermissionData] = useState([]);
  useEffect(() => {
    if (data) {
      handleGetPermissions();
    }
  }, [data]);

  const handleGetPermissions = async () => {
    try {
      const response = await getwindowmenupermission(data);
      setPermissionData(response.menuPermissions);

      const parents = response?.menuPermissions?.filter((item) =>
        response?.menuPermissions?.some(
          (child) => child.menu.parentID === item.menu.id
        )
      );

      setparent(parents);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handlePermission = async () => {
    const webObj = new WebUserMenuPermission();
    webObj.UserRole = data;
    webObj.MenuPermissions = PermissionData;
    try {
      const response = await savewindowmenupermission(webObj);
      if (response == true) {
        toast.success("success");
        closemodal();
        handleGetPermissions();
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleview = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, view: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handleadd = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, add: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handleedit = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, edit: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  const handledelete = (val, menuId) => {
    setPermissionData((prevData) => {
      // Create a new array with the updated item
      return prevData.map(
        (item) =>
          item.menu.id === menuId
            ? { ...item, deletion: val } // Update the 'view' property
            : item // Leave other items unchanged
      );
    });
  };
  console.log(parent,"paaaaarent");
  
  return (
    <div>
      <div className="modal fade" id="window-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Window Menu Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="form_body">
                    <div className="accordion" id="accordionExample">
                      {parent?.map((e, i) => (
                        <div className="accordion-item" key={i}>
                           
                          <h2 className="accordion-header" id={`heading${i}`}>
                            <div
                              className="accordion-button addproduct-icon d-flex align-items-center justify-content-between p-3 mb-2 bg-silver border rounded shadow-sm "
                              
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i}`}
                              aria-expanded="true"
                              aria-controls={`collapse${i}`}
                            >
                             
                           <p key={i}>{e.menu.name}</p>
                          
                               
                            </div>
                          </h2>
                        
                          <div
                            id={`collapse${i}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${i}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {PermissionData?.filter(
                                (ev) => ev.menu.parentID === e.menu.id
                              ) // Filter permissions based on parent ID
                                .map(
                                  (
                                    obj,
                                    index // Map through filtered permissions
                                  ) => (
                                    <div className="row" key={obj.menu.id}>
                                      <div className="col-8">
                                        {obj.menu.name}
                                      </div>
                                      <div className="col-4">
                                        {/* View Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.view}
                                          onChange={(e) => {
                                            handleview(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">View</label>
                                        &nbsp;&nbsp;
                                        {/* Add Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.add}
                                          onChange={(e) => {
                                            handleadd(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Add</label>
                                        &nbsp;&nbsp;
                                        {/* Edit Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.edit}
                                          onChange={(e) => {
                                            handleedit(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Edit</label>
                                        &nbsp;&nbsp;
                                        {/* Delete Checkbox */}
                                        <input
                                          type="checkbox"
                                          checked={obj.deletion}
                                          onChange={(e) => {
                                            handledelete(
                                              e.target.checked,
                                              obj.menu.id
                                            );
                                          }}
                                        />
                                        <label className="m-1">Delete</label>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-submit"
                        onClick={handlePermission}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WindowMenuPermission;
