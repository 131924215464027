


// import React, { useEffect, useRef, useState } from "react";
// import { getwebmenupermission, savewebmenupermission } from "../../../services/AdminApiServices";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchMenuServices } from "../../../redux/menuServices";
// import { fetchmenuPermissions } from "../../../redux/amcstatus";
// import toast from "react-hot-toast";
// import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";

// // FamilyTree Component
// const FamilyTree = ({ PermissionData, onUpdatePermissions }) => {
//   // Function to transform flat data into a tree structure
//   const buildTree = (data) => {
//     const idToNodeMap = {};
//     const rootNodes = [];

//     data.forEach((item) => {
//       idToNodeMap[item.menu.id] = { ...item, children: [] };
//     });

//     data.forEach((item) => {
//       if (item.menu.parentID === 0) {
//         rootNodes.push(idToNodeMap[item.menu.id]);
//       } else if (idToNodeMap[item.menu.parentID]) {
//         idToNodeMap[item.menu.parentID].children.push(idToNodeMap[item.menu.id]);
//       }
//     });

//     return rootNodes;
//   };

//   // Recursive rendering function
//   const renderTree = (node) => (
//     <div key={node.menu.id} className="row" style={{ marginLeft: "20px" }}>
//       <div className="col-4">
//         <strong>{node.menu.name}</strong>
//       </div>
//       {node.isSubMenu && (
//         <div className="col-8">
//           {["view", "add", "edit", "deletion"].map((type) => (
//             <span key={type}>
//               <input
//                 type="checkbox"
//                 checked={node[type]}
//                 onChange={(e) => onUpdatePermissions(node.menu.id, type, e.target.checked)}
//               />
//               <label className="m-1">{type.charAt(0).toUpperCase() + type.slice(1)}</label>
//               &nbsp;&nbsp;
//             </span>
//           ))}
//         </div>
//        )} 
//       {node.children.length > 0 && (
//         <div style={{ marginLeft: "10px" }}>
//           {node.children.map((child) => renderTree(child))}
//         </div>
//       )}
//     </div>
//   );

//   // Build the tree structure
//   const treeData = buildTree(PermissionData);

//   return <div>{treeData.map((node) => renderTree(node))}</div>;
// };

// // WebMenuPermission Component
// const WebMenuPermission = ({ data, RoleId }) => {
//   const [PermissionData, setPermissionData] = useState([]);
//   const [Data, setData] = useState([]);
//   const dispatch = useDispatch();
//   const { userId } = useSelector((state) => state.userauth);
//   const { UserServicelist } = useSelector((state) => state.userservice);

//   useEffect(() => {
//     if (data) {
//       handleGetPermissions();
//     }
//   }, [data, RoleId]);

//   const handleGetPermissions = async () => {
//     try {
//       const response = await getwebmenupermission(data);
//       setPermissionData(response.menuPermissions);
//       setData(response.menuPermissions);
//     } catch (error) {
//       console.error("Error fetching permissions:", error);
//     }
//   };

//   const handleUpdatePermissions = (menuId, type, value) => {
//     console.log(menuId,type,value);
    
//     setData((prevData) =>
//       prevData.map((item) =>
//         item.menu.id === menuId ? { ...item, [type]: value } : item
//       )
//     );
//     setPermissionData((prevData) =>
//       prevData.map((item) =>
//         item.menu.id === menuId ? { ...item, [type]: value } : item
//       )
//     );
//   };
// console.log(Data);

//   const handlePermissionSave = async () => {
//     console.log(Data,data);
    
//     try {
//     const webObj = new WebUserMenuPermission();
//        webObj.UserRole = data;
//        webObj.MenuPermissions = Data;
//       await savewebmenupermission(webObj);
//       toast.success("Permissions saved successfully!");
//        dispatch(fetchMenuServices({ id: userId.id }));
//        dispatch(fetchmenuPermissions(UserServicelist.userRole));
//       closeModal()
//     } catch (error) {
//       toast.error("Failed to save permissions.");
//       console.error("Error:", error);
//     }
//   };

//   const closeButtonRef = useRef();

//   const closeModal = () => {
//     if (closeButtonRef.current) {
//       closeButtonRef.current.click();
//     }
//   };

//   return (
//     <div>
//       <div className="modal fade" id="web-menu">
//         <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal w-50">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h4>Web Menu Permissions</h4>
//               <button
//                 type="button"
//                 className="close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 ref={closeButtonRef}
//               >
//                 <span aria-hidden="true">×</span>
//               </button>
//             </div>
//             <div className="modal-body" style={{}}>
//               <FamilyTree
//                 PermissionData={PermissionData}
//                 onUpdatePermissions={handleUpdatePermissions}
//               />
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-cancel"
//                 data-bs-dismiss="modal"
//               >
//                 Cancel
//               </button>
//               <button className="btn btn-submit" onClick={handlePermissionSave}>
//                 Save
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WebMenuPermission;





import React, { useEffect, useRef, useState } from "react";
import { getwebmenupermission, savewebmenupermission } from "../../../services/AdminApiServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenuServices } from "../../../redux/menuServices";
import { fetchmenuPermissions } from "../../../redux/amcstatus";
import toast from "react-hot-toast";
import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";


// WebMenuPermission Component
const WebMenuPermission = ({ data, RoleId }) => {
  const [PermissionData, setPermissionData] = useState([]);
  const [Data, setData] = useState([]);
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.userauth);
  const { UserServicelist } = useSelector((state) => state.userservice);

  useEffect(() => {
    if (data) {
      handleGetPermissions();
    }
  }, [data, RoleId]);

  const handleGetPermissions = async () => {
    try {
      const response = await getwebmenupermission(data);
      console.log(response,"menuuuuuuuuuu");
      
      setPermissionData(response.menuPermissions);
      setData(response.menuPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleUpdatePermissions = (menuId, type, value) => {
    console.log(menuId, type, value);

    setData((prevData) =>
      prevData.map((item) =>
        item.menu.id === menuId ? { ...item, [type]: value } : item
      )
    );
    setPermissionData((prevData) =>
      prevData.map((item) =>
        item.menu.id === menuId ? { ...item, [type]: value } : item
      )
    );
  };

  const handlePermissionSave = async () => {
    console.log(Data, data);

    try {
      const webObj = new WebUserMenuPermission();
      webObj.UserRole = data;
      webObj.MenuPermissions = Data;
      await savewebmenupermission(webObj);
      toast.success("Permissions saved successfully!");
      dispatch(fetchMenuServices({ id: userId.id }));
      dispatch(fetchmenuPermissions(UserServicelist.userRole));
      closeModal();
    } catch (error) {
      toast.error("Failed to save permissions.");
      console.error("Error:", error);
    }
  };

  const closeButtonRef = useRef();

  const closeModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  return (
    <div>
      <div className="modal fade" id="web-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Web Menu Permissions</h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FamilyTree
                PermissionData={PermissionData}
                onUpdatePermissions={handleUpdatePermissions}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button className="btn btn-submit" onClick={handlePermissionSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebMenuPermission;



// FamilyTree Component
const FamilyTree = ({ PermissionData, onUpdatePermissions }) => {
  // Function to transform flat data into a tree structure
  const buildTree = (data) => {
    const idToNodeMap = {};
    const rootNodes = [];

    data.forEach((item) => {
      idToNodeMap[item.menu.id] = { ...item, children: [] };
    });

    data.forEach((item) => {
      if (item.menu.parentID === 0) {
        rootNodes.push(idToNodeMap[item.menu.id]);
      } else if (idToNodeMap[item.menu.parentID]) {
        idToNodeMap[item.menu.parentID].children.push(idToNodeMap[item.menu.id]);
      }
    });

    return rootNodes;
  };

  // Recursive rendering function with dropdown for parentID === 0
  const renderTree = (node) => {
    const isRootNode = node.menu.parentID === 0;

    return (
      <div key={node.menu.id} className={`row ${isRootNode ? "" : ""}`}>
        {isRootNode ? (
          // Root node with dropdown
          <div className="modal-body custom-modal-body">
            <div className="accordion">
              <div
              style={{marginBottom:""}}
                className="accordion-button addproduct-icon d-flex align-items-center justify-content-between   bg-silver border rounded shadow-sm "
                type="button"
                data-bs-target={`#collapse${node.menu.id}`}
                // id={`dropdown-${node.menu.id}`}
                 data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls={`collapse${node.menu.id}`}
              >
             <p>{node.menu.name}</p>  
              </div>
              <div 
               id={`collapse${node.menu.id}`}
                                           className="accordion-collapse collapse"
                                          
                                           data-bs-parent="#accordionExample"
             
              //  style={{
              //   maxHeight: "600px",  // Set maximum height
              //   overflowY: "auto",   // Enable scrolling if content exceeds max-height
              // }} 
               aria-labelledby={`collapse${node.menu.id}`}>
                {node.children.map((child) => (
                  <li key={child.menu.id} className="dropdown-item">
                    {renderTree(child)}
                  </li>
                ))}
              </div>
            </div>
          </div>
        ) : (
          // Non-root node rendering
          <div className="col-12" style={{ marginLeft: "90px" }}>
            <div className="row">
              <div className="col-4">
                <strong>{node.menu.name}</strong>
              </div>
              <div className="col-8">
                {["view", "add", "edit", "deletion"].map((type) => (
                  <span key={type}>
                    <input
                      type="checkbox"
                      checked={node[type]}
                      onChange={(e) => onUpdatePermissions(node.menu.id, type, e.target.checked)}
                    />
                    <label className="m-1">{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                    &nbsp;&nbsp;
                  </span>
                ))}
              </div>
            </div>
            {node.children.length > 0 && (
              <div className="ms-3">
                {node.children.map((child) => renderTree(child))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Build the tree structure
  const treeData = buildTree(PermissionData);

  return <div>{treeData.map((node) => renderTree(node))}</div>;
};




// import React, { useEffect, useRef, useState } from "react";

// import {
//   getwindowmenupermission,
//   savewindowmenupermission,
// } from "../../../services/AdminApiServices";
// import { UserRole } from "../../../core/json/UserRole";
// import { useForm } from "react-hook-form";
// import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";
// import toast from "react-hot-toast";
// import { Checkbox } from "antd";
// import { ChevronDown, Info} from "feather-icons-react/build/IconComponents";

// const WindowMenuPermission = ({ data }) => {
//   const { register, setValue, getValues, watch } = useForm({
//     mode: "onChange",
//     reValidateMode: "onChange",
//   });
//   const [parent, setparent] = useState([]);
//   const [PermissionData, setPermissionData] = useState([]);
//   useEffect(() => {
//     if (data) {
//       handleGetPermissions();
//     }
//   }, [data]);

//   const handleGetPermissions = async () => {
//     try {
//       const response = await getwindowmenupermission(data);
//       setPermissionData(response.menuPermissions);

//       const parents = response?.menuPermissions?.filter((item) =>
//         response?.menuPermissions?.some(
//           (child) => child.menu.parentID === item.menu.id
//         )
//       );

//       setparent(parents);
//     } catch (error) {
//       console.error("Error fetching states:", error);
//     }
//   };
//   const closeButtonRef = useRef();

//   const closemodal = () => {
//     if (closeButtonRef.current) {
//       closeButtonRef.current.click();
//     }
//   };
//   const handlePermission = async () => {
//     const webObj = new WebUserMenuPermission();
//     webObj.UserRole = data;
//     webObj.MenuPermissions = PermissionData;
//     try {
//       const response = await savewindowmenupermission(webObj);
//       if (response == true) {
//         toast.success("success");
//         closemodal();
//         handleGetPermissions();
//       }
//     } catch (error) {
//       console.error("Error fetching states:", error);
//     }
//   };
//   const handleview = (val, menuId) => {
//     setPermissionData((prevData) => {
//       // Create a new array with the updated item
//       return prevData.map(
//         (item) =>
//           item.menu.id === menuId
//             ? { ...item, view: val } // Update the 'view' property
//             : item // Leave other items unchanged
//       );
//     });
//   };
//   const handleadd = (val, menuId) => {
//     setPermissionData((prevData) => {
//       // Create a new array with the updated item
//       return prevData.map(
//         (item) =>
//           item.menu.id === menuId
//             ? { ...item, add: val } // Update the 'view' property
//             : item // Leave other items unchanged
//       );
//     });
//   };
//   const handleedit = (val, menuId) => {
//     setPermissionData((prevData) => {
//       // Create a new array with the updated item
//       return prevData.map(
//         (item) =>
//           item.menu.id === menuId
//             ? { ...item, edit: val } // Update the 'view' property
//             : item // Leave other items unchanged
//       );
//     });
//   };
//   const handledelete = (val, menuId) => {
//     setPermissionData((prevData) => {
//       // Create a new array with the updated item
//       return prevData.map(
//         (item) =>
//           item.menu.id === menuId
//             ? { ...item, deletion: val } // Update the 'view' property
//             : item // Leave other items unchanged
//       );
//     });
//   };
//   console.log(parent,"paaaaarent");
  
//   return (
//     <div>
//       <div className="modal fade" id="window-menu">
//         <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
//           <div className="modal-content">
//             <div className="page-wrapper-new p-0">
//               <div className="content">
//                 <div className="modal-header border-0 custom-modal-header">
//                   <div className="page-title">
//                     <h4>Window Menu Permissions</h4>
//                   </div>
//                   <button
//                     type="button"
//                     className="close"
//                     data-bs-dismiss="modal"
//                     aria-label="Close"
//                     ref={closeButtonRef}
//                   >
//                     <span aria-hidden="true">×</span>
//                   </button>
//                 </div>
//                 <div className="modal-body custom-modal-body">
//                   <div className="form_body">
//                     <div className="accordion" id="accordionExample">
//                       {parent?.map((e, i) => (
//                         <div className="accordion-item" key={i}>
                           
//                           <h2 className="accordion-header" id={`heading${i}`}>
//                             <div
//                               className="accordion-button addproduct-icon d-flex align-items-center justify-content-between p-3 mb-2 bg-silver border rounded shadow-sm "
                              
//                               data-bs-toggle="collapse"
//                               data-bs-target={`#collapse${i}`}
//                               aria-expanded="true"
//                               aria-controls={`collapse${i}`}
//                             >
//                               <Info className="add-info" />
//                            <p key={i}>{e.menu.name}</p>
                          
                               
//                             </div>
//                           </h2>
                        
//                           <div
//                             id={`collapse${i}`}
//                             className="accordion-collapse collapse"
//                             aria-labelledby={`heading${i}`}
//                             data-bs-parent="#accordionExample"
//                           >
//                             <div className="accordion-body">
//                               {PermissionData?.filter(
//                                 (ev) => ev.menu.parentID === e.menu.id
//                               ) // Filter permissions based on parent ID
//                                 .map(
//                                   (
//                                     obj,
//                                     index // Map through filtered permissions
//                                   ) => (
//                                     <div className="row" key={obj.menu.id}>
//                                       <div className="col-8">
//                                         {obj.menu.name}
//                                       </div>
//                                       <div className="col-4">
//                                         {/* View Checkbox */}
//                                         <input
//                                           type="checkbox"
//                                           checked={obj.view}
//                                           onChange={(e) => {
//                                             handleview(
//                                               e.target.checked,
//                                               obj.menu.id
//                                             );
//                                           }}
//                                         />
//                                         <label className="m-1">View</label>
//                                         &nbsp;&nbsp;
//                                         {/* Add Checkbox */}
//                                         <input
//                                           type="checkbox"
//                                           checked={obj.add}
//                                           onChange={(e) => {
//                                             handleadd(
//                                               e.target.checked,
//                                               obj.menu.id
//                                             );
//                                           }}
//                                         />
//                                         <label className="m-1">Add</label>
//                                         &nbsp;&nbsp;
//                                         {/* Edit Checkbox */}
//                                         <input
//                                           type="checkbox"
//                                           checked={obj.edit}
//                                           onChange={(e) => {
//                                             handleedit(
//                                               e.target.checked,
//                                               obj.menu.id
//                                             );
//                                           }}
//                                         />
//                                         <label className="m-1">Edit</label>
//                                         &nbsp;&nbsp;
//                                         {/* Delete Checkbox */}
//                                         <input
//                                           type="checkbox"
//                                           checked={obj.deletion}
//                                           onChange={(e) => {
//                                             handledelete(
//                                               e.target.checked,
//                                               obj.menu.id
//                                             );
//                                           }}
//                                         />
//                                         <label className="m-1">Delete</label>
//                                       </div>
//                                     </div>
//                                   )
//                                 )}
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                   <div className="col-lg-12">
//                     <div className="modal-footer-btn">
//                       <button
//                         type="button"
//                         className="btn btn-cancel me-2"
//                         data-bs-dismiss="modal"
//                       >
//                         Cancel
//                       </button>
//                       <button
//                         className="btn btn-submit"
//                         onClick={handlePermission}
//                       >
//                         Save
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WindowMenuPermission;