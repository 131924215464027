import React, { useEffect, useState } from "react";
import { getSalesReport } from "../../services/ReportApiService";
import { Calendar, File, RefreshCw, Sliders } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { DatePicker, Pagination } from "antd";
import Table from "../../core/pagination/datatable";
import Select from "react-select";
function GSTReports() {
  const [gstData, setGstData] = useState([]);
  useEffect(() => {
    handleSaleReportList();
  }, []);

  const handleSaleReportList = async () => {
    try {
      const response = await getSalesReport();
      setGstData(response);
    } catch (error) {
      console.log();
    }
  };

  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "Dine In",
      dataIndex: "dineIn",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Delivery Account",
      dataIndex: "billNo",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },

    {
      title: "Take Away",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Hut",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Total",
      dataIndex: "discount",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Gst",
      dataIndex: "netTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Taxable",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "GST Amount",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "CGST",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "SGST",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Net Total",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Start BillNo",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "End BillNo",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "No Of Bills",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>GST Report</h4>
              </div>
            </div>
            <ul className="table-top-head"></ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn"></div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            {/* <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          // value={searchText}
                          // onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-blocks">
                      <label htmlFor="from-date">From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          // selected={fromDate}
                          // value={fromDate}
                          // onChange={handleFromDateChange}
                          // startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-blocks mr-2">
                      <label htmlFor="to-date">To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // value={toDate}
                          // onChange={handleToDateChange}
                          // minDate={fromDate}
                          // startDate={fromDate}
                          selectsEnd
                          // disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <File className="info-img" />
                        <Select
                          className="img-select"
                          // options={section}
                          // value={sectionval}
                          // onChange={setsectionval}
                          classNamePrefix="react-select"
                          placeholder="Enter Section"
                          isClearable
                        />
                      </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <Link
                      className="btn btn-filters ms-auto"
                      // onClick={() => {
                      //   setCurrentPage(1);
                      //   setPageSize(20);
                      //   handleReportList(currentPage, pageSize);
                      //   // handlegetsalesReport(currentPage, pageSize);
                      // }}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      search{" "}
                    </Link>
                  </div>
                  <div className="input-blocks">
                    <button
                      className="btn btn-reset rounded-2"
                      // onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                
                
                </div> */}
                <div className="table-top">
      {/* Filter Row */}
      <div className="d-flex align-items-center gap-3 flex-wrap">
        {/* Search Input */}
        <div className="search-input">
        <Link to="#" className="btn btn-searchset">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={2}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg> */}
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control"
                          style={{height:"40px"}}
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          // value={searchText}
                          // onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                
        
        

        {/* From Date */}
        <div className="input-blocks">
          <label htmlFor="from-date" className="d-block">From Date</label>
          <div className="input-groupicon calender-input">
            <Calendar className="info-img" />
            <DatePicker
              className="datetimepicker"
              id="from-date"
              placeholderText="From Date"
              // selected={fromDate}
              // onChange={handleFromDateChange}
              selectsStart
              // startDate={fromDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        {/* To Date */}
        <div className="input-blocks">
          <label htmlFor="to-date" className="d-block">To Date</label>
          <div className="input-groupicon calender-input">
            <Calendar className="info-img" />
            <DatePicker
              id="to-date"
              placeholderText="To Date"
              // selected={toDate}
              // onChange={handleToDateChange}
              selectsEnd
              // startDate={fromDate}
              // minDate={fromDate}
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
        </div>

        {/* Section Dropdown */}
        <div className="input-blocks">
          <label htmlFor="section" className="d-block">Section</label>
          <div className="d-flex align-items-center">
            <File className="info-img me-2" />
            <Select
              className="img-select"
              // options={sectionOptions}
              // value={sectionValue}
              // onChange={handleSectionChange}
              classNamePrefix="react-select"
              placeholder="Enter Section"
              isClearable
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="ms-auto d-flex align-items-center gap-2">
          <button
            className="btn btn-filters"
            // onClick={() => console.log("Search triggered!")}
          >
            <i data-feather="search" className="feather-search" /> Search
          </button>
          <button
            className="btn btn-reset rounded-2"
            // onClick={handleReset}
          >
            <i data-feather="refresh-cw" className="feather-refresh-cw" /> Reset
          </button>
        </div>
      </div>
    </div>
    <div className="float-right align-items-center">
    <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu p-2">
                    {/* {menuItems?.map((obj, i) => {
                      return (
                        <li key={i}>
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(obj.value, e.target.checked)
                            }
                            defaultChecked={obj.checked}
                          >
                            <div style={{ color: "black" }}> {obj.label}</div>
                          </Checkbox>
                        </li>
                      );
                    })} */}
                  </ul>
                </div>
                </div>
              {/* /Filter */}

              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={gstData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
    </div>
  );
}

export default GSTReports;
