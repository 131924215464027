import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
import Table from "../../../core/pagination/datatable";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import {
  fetchUnit,
  fetchProduct,
  fetchTax,
  fetchSettings,
  fetchCustomer,
} from "../../../redux/userSetting";
import { DatePicker, Select, Tabs } from "antd";
import { getConsolidatedReportSectionwise } from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";
import LoadingUtils from "../../../core/utils/LoadingUtils";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../../core/utils/MasterReportUtils";

const BranchReport = () => {
  const [searchText, setSearchText] = useState("");
  const [reportData, setreportData] = useState([]);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
   const [decimalpoints, setDecimalPoints] = useState(2);
    const {
      productList,
      unitList,
      settingList,
      customerList,
      formatedproductList,
    } = useSelector((state) => state.usersetting);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    //setreportData(salesReportConsol);
    handleReportList();
     dispatch(fetchUnit());
        dispatch(fetchProduct(branch));
        dispatch(fetchTax());
        dispatch(fetchSettings(branch));
    
        const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
        const currfmt = match ? match[0]?.length - 1 : 0;
        setDecimalPoints(currfmt);
  }, [branch, fromDate, toDate]);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleReportList = async () => {
    setloading(true);
    const DatatablePagingParameters = new DatatablePagingParameter();
    const formattedfromDate = dayjs(fromDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).endOf("day").format("YYYY-MM-DD");
    // const formattedfromDate = dayjs(fromDate).add(1, "day");
    // const formattedtoDate = dayjs(toDate).add(1, "day");
    const Filters = new Filterclass();
    DatatablePagingParameters.PageSize = -1;
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getConsolidatedReportSectionwise({
        DatatablePagingParameters,
        Filters,
      });
      setloading(false);
      // Check if the response contains valid array data
      const salesReportConsol = response?.data?.salesReportConsol || [];
console.log("object",response?.data)
      if (Array.isArray(salesReportConsol)) {
        const dataArray = salesReportConsol
           ?.filter((item) => (!item.cancelled && !item.refund))
          ?.map((item) => {
            return {
              branch: item.branch?.name,
              trnDate: dayjs(item.transactionDate).format("DD-MM-YYYY"),
              netTotal: item.netTotal,
            };
          });

        console.log(dataArray, "dataArray");

        setreportData(dataArray);
      } else {
        console.error("Data is not in the expected array format");
        setreportData([]);
      }
    } catch (error) {
      setloading(false);
      console.error(error?.response?.data?.Message || "Something went wrong");
      setreportData([]);
    }
  };
  // Safe-check if reportData is an array
  const {
    branches,
    trnDates,
    detailData,
    branchTotals,
    grandTotal,
    summaryData,
  } = useMemo(() => {
    const safeReportData = Array.isArray(reportData) ? reportData : [];

    const branches = [...new Set(safeReportData?.map((item) => item.branch))];
    const trnDates = [...new Set(safeReportData?.map((item) => item.trnDate))];

    let sumBranchNet = 0;

    // Grouping the data by trnDate and branch, summing the netTotal values
    const groupedData = safeReportData.reduce(
      (acc, { branch, trnDate, netTotal }) => {
        // Check if entry for this date already exists
        if (!acc[trnDate]) {
          acc[trnDate] = { trnDate, branchTotals: {} };
        }

        // Add the netTotal to the branch total for this date
        if (!acc[trnDate].branchTotals[branch]) {
          acc[trnDate].branchTotals[branch] = 0;
        }

        acc[trnDate].branchTotals[branch] += netTotal;

        return acc;
      },
      {}
    );

    // Prepare the detailData array
    const detailData = Object.entries(groupedData)?.map(
      ([date, { branchTotals }]) => {
        const row = { key: date, trnDate: date };
        let branchTotal = 0;

        // For each branch, sum up the totals for the date
        branches.forEach((branch) => {
          const branchNetTotal = branchTotals[branch] || 0;
          row[branch] = branchNetTotal;
          branchTotal += branchNetTotal;
          sumBranchNet += branchNetTotal;
        });

        row.branchTotal = branchTotal;
        return row;
      }
    );

    const branchTotals =
      branches?.map((branch) => {
        // Log current branch being processed

        const total = safeReportData
          ?.filter((item) => {
            const isMatchingBranch = item?.branch === branch;

            return isMatchingBranch;
          })
          ?.reduce((sum, item) => {
            const netTotal = item?.netTotal || 0;

            return sum + netTotal;
          }, 0);

        return { branch, total };
      }) || []; // Fallback to empty array if branches is undefined

    const grandTotal = branchTotals?.reduce((sum, branch) => {
      const branchTotal = branch?.total || 0;

      return sum + branchTotal;
    }, 0);

    // Grand total calculation across all branches
    const grandTotals = branches.reduce((acc, branch) => {
      acc[branch] = detailData.reduce(
        (sum, row) => sum + (row[branch] || 0),
        0
      );
      return acc;
    }, {});

    const grandGrandTotal = detailData.reduce(
      (sum, row) => sum + row.branchTotal,
      0
    );

    // Add grand total row
    detailData.push({
      key: "total",
      trnDate: "Total",
      ...grandTotals,
      branchTotal: grandGrandTotal,
    });
    const totalRow = {
      key: "total",
      branch: "Total",
      total: grandGrandTotal,
    };
    branchTotals.push(totalRow);

    return {
      branches,
      trnDates,
      detailData,
      branchTotals,
      grandTotal,
    };
  }, [reportData]);

  const detailColumns = [
    {
      title: "Date",
      dataIndex: "trnDate",
      key: "trnDate",
    },
    ...branches.map((branch) => ({
      title: branch,
      dataIndex: branch,
      key: branch,
      render: (value) => (value !== undefined ? value?.toFixed(decimalpoints) : 0),
    })),
    {
      title: "Total",
      dataIndex: "branchTotal",
      key: "branchTotal",
      render: (value) => (value !== undefined ? value?.toFixed(decimalpoints) : 0),
    },
  ];

  const summaryColumns = [
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (value) => (value !== undefined ? value?.toFixed(decimalpoints) : 0),
    },
  ];
  function handleReset() {
    setFromDate((prev) => dayjs());
    setToDate((prev) => dayjs());
    setSearchText("");
    setreportData([]);
    // branchTotals = [];
    // // detailData = [];
  }
  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(detailColumns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: detailData, columns: detailColumns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(summaryColumns);
        console.log("fieldMapping of second table:", fieldMapping);
        return { data: branchTotals, columns: summaryColumns, fieldMapping };
      default:
        return { data: [], columns: [] };
    }
  };
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Consolidated Branch Reports</h4>
                <h6>Manage branchwise consolidated reports here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() =>
                      exportMasterPDF(getActiveTabData, "Branch wise Report")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(getActiveTabData, "Branch wise Report")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(getActiveTabData, "Branch wise Report")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
            
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-blocks">
                        <label>From Date</label>
                        <DatePicker
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          // className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // selected={toDate}
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate} // disable until from date is selected
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-blocks p-3 mt-3">
                    <Link
                      className="btn btn-filters rounded-2 "
                      onClick={handleReportList}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search{" "}
                    </Link>
                  </div>
                  <div className="input-blocks mt-3">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Detailed View" key="1">
                    <Table columns={detailColumns} dataSource={detailData} />
                  </TabPane>
                  <TabPane tab="Summary View" key="2">
                    <Table columns={summaryColumns} dataSource={branchTotals} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BranchReport;
