import React, { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom"; // Assuming you're using react-router
import {
  addSectionSettings,
  addSectionTable,
  getSectionTable,
} from "../../services/MasterApiServices";
import { TableAssignment } from "../../core/json/TableAssignment";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { SectionClass } from "../../core/json/SectionClass";
import { sectionTable } from "../../core/json/Table";
const SectionAsignTable = ({ data, selectedSection }) => {
  const { register, handleSubmit, reset, control, watch, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tables",
  });
  // useEffect(() => {
  //   remove();

  //   if (data && data.tables?.length > 0) {
  //     console.log("data", data);
  //     data.tables.forEach((table) => {
  //       append({
  //         name: table.name,
  //         capacity: table.capacity,
  //         guid: table.guid,
  //         id: table.id,
  //       });
  //     });
  //   }
  // }, [data, selectedSection]);
  useEffect(() => {
    remove();
    if (!data || !Array.isArray(data.tables) || data.tables.length === 0) {
      reset(); // Clear previous tables
      console.warn("No tables found for the selected section");
      return;
    }

    console.log("data", data.tables);

    data.tables.forEach((table) => {
      append({
        name: table.name,
        capacity: table.capacity,
        guid: table.guid,
        id: table.id,
      });
    });
  }, [data, selectedSection]);

  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [tableName, setTableName] = useState(""); // State to store table name
  const [capacity, setCapacity] = useState(""); // State to store capacity
  const [deletedID, setDeletedID] = useState([]);

  // Handle form submission
  const handleFormSubmit = async (formData) => {
    const objsection = new TableAssignment();
    const objsectionclass = new SectionClass();
    objsectionclass.Branch = branch;
    objsectionclass.guid = selectedSection.guid;
    objsectionclass.name = selectedSection.name;
    objsectionclass.id = selectedSection.id;
    objsection.Section = objsectionclass;

    objsection.createdUser = `{${userId?.id}}`;
    objsection.updatedUser = `{${userId?.id}}`;
    objsection.branch = branch;

    // for (const row of formData.tables) {
    //   const objtable = new sectionTable();
    //   objtable.Name = row.name;
    //   objtable.guid = row?.guid;
    //   objtable.id = row?.id;
    //   objtable.Capacity = row.capacity;
    //   objtable.branch = branch;
    //   objsection.Tables.push(objtable);
    // }
    formData.tables.forEach((row, index) => {
      const objtable = new sectionTable();
      objtable.Name = row.name;
      objtable.guid = row?.guid;
      objtable.id = row?.id;
      objtable.Capacity = row.capacity;
      objtable.branch = branch;
      if (deletedID.includes(index)) {
        objtable.version = 0;
        objtable.isDeleted = true;
      }
      objsection.Tables.push(objtable);
    });

    try {
      const response = await addSectionTable(objsection);
      if (response == true) {
        toast.success("success");
        closemodal();
        remove();
      } else if (response == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Form submission error:", error);
    }
  };

  // Add a new table row
  const handleAddSection = () => {
    if (tableName && capacity) {
      append({ name: tableName, capacity }); // Add the new table with name and capacity
      setTableName(""); // Clear the input after adding
      setCapacity(""); // Clear the input after adding
    }
  };

  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const resetmodal = () => {
    remove();
    setDeletedID([]); // Clear hidden rows

    if (data && data.tables?.length > 0) {
      data.tables.forEach((table) => {
        append({
          name: table.name,
          capacity: table.capacity,
          guid: table.guid,
          id: table.id,
        });
      });
    }
  };

  function handleDelete(index) {
    setDeletedID((prev) => [...prev, index]);
   

    toast.success("Row marked for deletion. Changes will apply after saving.");
  }
  return (
    <div>
      <div className="modal fade" id="sectionasign" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{data?.name || "Default Section"} Section Tables</h4>{" "}
                    {/* Safe access of data.name */}
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          {/* <label>
                            Table Name
                            <span className="text-danger">*</span>
                          </label> */}
                          <input
                            type="text"
                            placeholder="Table Name"
                            value={tableName}
                            onChange={(e) => setTableName(e.target.value)} // Update the local state
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          {/* <label>
                            Capacity
                            <span className="text-danger">*</span>
                          </label> */}
                          <input
                            type="number"
                            placeholder="Capacity"
                            className="form-control p-3"
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)} // Update the local state
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <button
                          className="btn btn-success"
                          onClick={handleAddSection}
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div
                      className="row p-2"
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        position: "relative",
                      }}
                    >
                      <div className="row">
                        <div className="col-4">
                          Name<span className="text-danger">*</span>
                        </div>
                        <div className="col-4">
                          Capacity<span className="text-danger">*</span>
                        </div>
                        <div className="col-4">Action</div>
                      </div>
                      {fields
                        // .filter((_, index) => !deletedID.includes(index))
                        .map((field, index) => (
                          <React.Fragment key={field.id}>
                            <hr />
                            <div className="row">
                              <div className="col-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`tables.${index}.name`, {
                                    required: true,
                                  })}
                                  defaultValue={field.name} // Display added table name
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`tables.${index}.capacity`, {
                                    required: true,
                                  })}
                                  defaultValue={field.capacity} // Display added capacity
                                  readOnly
                                />
                              </div>
                              <div className="col-4 promo-delete">
                                <div className="edit-delete-action">
                                  <Link
                                    className="confirm-text p-2"
                                    to="#"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="feather-trash-2"
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={resetmodal}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-submit"
                          disabled={fields?.length == 0}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAsignTable;
