import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import {
  Calendar,
  MinusCircle,
} from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";
// import { ProductClass } from "../../../core/json/Product";
// import { getUnitList } from "../../../services/MasterApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
// import {
//   getStockByBranch,
//   addEditStock,
// } from "../../../services/StockApiServices";
// import { InvStockAdjustment } from "../../../core/json/invStockAdjust";
// import { InvStockAdjustmentDetail } from "../../../core/json/invStockAdjustDetail";
import { useSelector, useDispatch } from "react-redux";
import {
  getOpeningBalance,
  saveOpeningBalance,
//  deleteOpeningBalance,
} from "../../services/AccountApiServices";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { getAccountledger } from "../../services/AccountApiServices";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
import { OpeningStockMaster } from "../../core/json/OpeningStockMaster";
import { FinancialYear } from "../../core/json/FinancialYear";
import { Company } from "../../core/json/Company";
import { LedgerBranchOpeningDetail } from "../../core/json/OpeningDetail";
import { LedgerBranchOpeningMaster } from "../../core/json/OpeningMaster";
import { LedgerClass } from "../../core/json/Ledger";
import Item from "antd/es/list/Item";

const OpeningBalanceModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryDate: dayjs().format("YYYY-MM-DD"),
        invdate: dayjs().format("YYYY-MM-DD"),
            addition: 0,
            subtraction: 0,
            round: 0,
    },
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const dispatch = useDispatch();
  const [isRowAdded, setIsRowAdded] = useState(false);
  const { userId } = useSelector((state) => state.userauth);
  const { branch } = useSelector((state) => state.branchlist);
  const { formatedLedger } = useSelector((state) => state.ledger);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const [loading, setloading] = useState(false);
  // const [unitData, setunitData] = useState([]);
  // const [products, setProducts] = useState([]);
  const [ledgerData, setLedgerData] = useState();
  // const [decimalpoints, setDecimalPoints] = useState(2);
  // const [selectedType, setSelectedType] = useState(0);
 


  const handleno = async () => {
    try {
      const response = await getOpeningBalance(branch);

      const receiptData = Array.isArray(response) ? response : [];
     
  // Find the maximum number from the existing entries
      const maxEntryNumber =
        receiptData?.length > 0
          ? receiptData
              ?.map((data) => parseInt(data.no))
              ?.reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`no`, counter);
      counter++;
    } catch (error) {
      console.error("Failed to fetch payments data", error);
    }
  };
  useEffect(() => {
    resetmodal();
  }, [data, append,remove]);

  function resetmodal() {
    if (mode === "edit" && data) {
   
     // setValue("remark", data.remarks);
     // setValue("no", data.no);
     // setValue("invdate", dayjs(data.date));
      const resetData = {
        no: data.no,
        remark: data.remarks,
        date: dayjs(data.date),
        items: data?.ledgerBranchOpeningDetails?.map((detail) => ({
          ledger: detail?.ledger?.guid,
          credit: detail.credit,
          debit: detail.debit,
          refNo: detail.refNo,
          remarks: detail.remarks,
          
        })),
      };
      reset(resetData);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
    if (mode === "add") {
      handleno();
      if (!isRowAdded && fields?.length === 0) {
        handleAddRow();
        setIsRowAdded(true);
      }
    }
  };
  fetchData();
    //handleLedgerList();
    // handleCurrencyList();

 //  dispatch(fetchBranchSettings(branch.guid));
  }, []);


  const handleAddRow = () => {
    append({
      batch: "0",
      baseQuantity: 0,
      adjQuantity: 0,
      unit: "",
      product: null,
    });
  };
 


  // const handleLedgerList = async () => {
  //   try {
  //     const response = await getAccountledger();
  //     if (Array.isArray(response)) {
  //       setLedgerData(
  //         response?.map((e) => ({
  //           value: e.guid,
  //           label: e.name,
  //         }))
  //       );
  //     } else {
  //       setLedgerData([]);
  //     }
  //   } catch (error) {
  //     setLedgerData([]);
  //   }
  // };
  const handleFormSubmit = async (formdata) => {
    setloading(true);
    const objOpeningBal = new LedgerBranchOpeningMaster();
    const objfinance = new FinancialYear();
    objOpeningBal.date = dayjs(formdata.invdate).format("YYYY-MM-DD");
    // objOpeningBal.date = dayjs(formdata.invdate).format("YYYY-MM-DD");
    objOpeningBal.no = formdata.no;
    objOpeningBal.id = mode == "edit" ? data.id : 0;
    objOpeningBal.guid = mode == "edit" ? data.guid : "";
    objOpeningBal.remarks = formdata.remark;
    objOpeningBal.branch = branch;
    objOpeningBal.financialYear = objfinance;
    objOpeningBal.company = companyInfolist[0];
    objOpeningBal.createdUser = userId.id;
    objOpeningBal.updatedUser = userId.id;

    for (const row of formdata.items) {
      const objdetail = new LedgerBranchOpeningDetail();
      const objLedger = new LedgerClass();
      objLedger.guid = row.ledger;
      objdetail.ledger = objLedger;
      objdetail.credit = Number(row.credit);
      objdetail.debit = Number(row.debit);
      objdetail.refNo = row.refNo;
      objdetail.remarks = row.remarks;

      objOpeningBal.ledgerBranchOpeningDetails.push(objdetail);
    }

    try {
      const response = await saveOpeningBalance(objOpeningBal);
      console.log("objecteirie",response)
      if (response) {
        console.log("objectethfh")
        toast.success("Success");
        handleRefresh();
        handleClose();
        setloading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling opening balance:",
        error?.response?.data?.Message
      );
      setloading(false);
    }
  };

  function handleRemove(index) {
    if (fields.length > 1) {
      remove(index);
    } else {
      toast.error("At least one row entry is required");
    }
  }
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Opening Balance"
                        : "Add Opening Balance"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Date <span className="text-danger">*</span>
                            </label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="invdate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className=" col-12 ">
                          <div className="input-blocks">
                            <label>Remark</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                {...register(`remark`)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Ledger
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Debit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Credit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Ref No.
                              </th>
                              <th className="sticky-header">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields?.map((obj, index) => {
                          
                             return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].ledger`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={formatedLedger}
                                          value={formatedLedger?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].debit`, {
                                        required: "Debit is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "Debit must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.debit && (
                                        <span className="text-danger small">
                                          {errors.items[index].debit.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].credit`, {
                                        required: "Credit is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "Credit must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.credit && (
                                        <span className="text-danger small">
                                          {errors.items[index].credit.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].refNo`)}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.refNo && (
                                        <span className="text-danger small">
                                          {errors.items[index].refNo.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].remarks`)}
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => handleRemove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={handleAddRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>
                      {!loading && (
                        <div className="row">
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              onClick={resetmodal}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-submit add-sale"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OpeningBalanceModal;
